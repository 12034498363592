export let unsworthobj: any;
export let queryUrl = '';
export let connectionCheck = true;
// export const SearchAPI_Server ="http://81.106.219.219:81/";
// export const SearchAPI_Method = "webservices/oneclick_v2.asp";


// export const SearchAPI_Server ="https://unsworth-agilexcyber.c9users.io";
// export const SearchAPI_Method = "unsworth/backend/pathwayTrack.php";

// Main ONE
export const SearchAPI_Server = "https://oneclick.unsworth.uk";
export const SearchAPI_Method = "api/pathwayTrack.php";
export const Upload_URL ="https://oneclick.unsworth.uk/api/fileuploads.php";
export const imgURL = "http://www.unsworth.uk/images/oneclickreps/";
export function setContainerDetails(data: any,no){
   unsworthobj = data;
   queryUrl = location.origin+"/status?number="+no;
}
export function setConnectionCheck(conn: any) {
   connectionCheck = conn;
}
export const completeFormURL ="http://assets.uglog.com/nes/?var=";