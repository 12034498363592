import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-milestone2',
  templateUrl: './milestone2.component.html',
  styleUrls: ['./milestone2.component.css']
})
export class Milestone2Component implements OnInit {
  b_made: boolean;
  g_departed: boolean;
  g_arrived: boolean;
  g_released: boolean;
  good_released_date:any;
  @Input() unsDetails: any;
  @Output() valueChange = new EventEmitter();
  constructor() {
    this.b_made = true;
    this.g_departed = false;
    this.g_arrived = false;
    this.g_released = false;
  }

  ngOnInit() {
    this.milestoneFunctionality2();
  }
  milestoneFunctionality2() {
    if (this.unsDetails.RLS_Event) {
      this.good_released_date =  moment(this.unsDetails.RLS_Event,'DD/MM/YYYY hh:mm:ss').format('dddd Do MMMM');
      this.g_departed = true;
      this.g_arrived = true;
      this.g_released = true;
      this.valueChange.emit(true);
    }
    if (this.unsDetails.ATA) {
      this.g_departed = true;
      this.g_arrived = true;
    }
    if (this.unsDetails.ATD) {
      this.g_departed = true;
    }
  }
}
