import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as config from '../services/api.config';
import * as moment from 'moment';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PagerService } from '../services/index.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})

export class StatusComponent implements OnInit {
  forward_animation:boolean;
  b_made: boolean;
  g_departed: boolean;
  g_arrived: boolean;
  c_cleared: boolean;
  g_delivered: boolean;
  uns_importair: boolean;
  uns_exportair: boolean;
  uns_importsea: boolean;
  uns_exportsea: boolean;
  uns_importfwd: boolean;
  uns_exportfwd: boolean;
  showSideBarAnimation:boolean;
  uns_status_obj: any;
  // uns_process_show: boolean;
  uns_documents2: boolean;
  formData: FormData;
  dragOver: boolean;
  comInvoicefile: FormData = new FormData();
  f_size_err: boolean;
  f_type_err: boolean;
  msg: string;
  commInv: string;
  others: string;
  // pager object
  pager: any = {};

  // paged items
  copied: boolean = false;
  pagedItems: any[];
  allSipmentDatas: any;
  open_modal: boolean;
  open_modal1: boolean;
  commInvImpUpload: boolean;
  commInvExpUpload: boolean;
  nesDecUpload: boolean;
  gspCertImpUpload: boolean;
  commInvUploadf_name: string;
  gspCertImpUploadf_name: string;
  commInvExpUploadf_name: string;
  nesDecUploadf_name: string;
  number: string;
  pin: string;
  search: boolean = false;
  error: boolean = false;
  file: File;
  shipment_reference: string = "";
  completeForm_url: string;
  operator_log_empty: boolean = false;
  milestone_completed: boolean = false;
  isuploaded:boolean = false;
  smartLink: string='';
  debug: any;
  emptyRes: boolean = false;
  connProblem: boolean = false;
  customer_mailto:string ="";
  constructor(
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrManager,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.b_made = true;
    this.g_departed = false;
    this.g_arrived = false;
    this.c_cleared = false;
    this.g_delivered = false;
    this.uns_importair = false;
    this.uns_exportair = false;
    this.uns_importsea = false;
    this.uns_exportsea = false;
    this.uns_importfwd = false;
    this.uns_exportfwd = false;
    this.showSideBarAnimation = false;

  
    // this.uns_process_show = false;
    this.uns_documents2 = false;
    this.msg = '';
    this.commInv = '';
    this.others = '';
    this.open_modal = false;
    this.open_modal1 = false;
    this.commInvImpUpload = false;
    this.commInvExpUpload = false;
    this.nesDecUpload = false;
    this.gspCertImpUpload = false;
   

    this.activatedRoute.queryParams.subscribe(params => {
      this.number = params['number'];
      // this.pin = params['pin'];
      this.debug = !(params['debug'])?false:true
      if (this.number) {
        this.getContainerDetailsData();
      } else {
        this.setContainerData();
      }

    });

  }

  ngOnInit() {
    setTimeout(()=>{this.showSideBarAnimation = true;},1000);
    this.forward_animation = true;
    this.completeForm_url = config.completeFormURL;
    this.smartLink = config.queryUrl!=''?config.queryUrl:'';
    if ((typeof config.unsworthobj == "undefined" || !config.unsworthobj)) {
      if (!this.error && !this.search) {
        this.allSipmentDatas = [];
        this.router.navigate(['']);
      }
 
    } else {
      this.uns_status_obj = config.unsworthobj[0];
      this.customer_mailto = this.uns_status_obj ? this.uns_status_obj.Operator_Rep_Email:"";
      console.log("this.uns_status_obj",this.uns_status_obj)

      if (this.uns_status_obj.Transport_Mode == "SEA") {
        if (this.uns_status_obj.Discharge_Port !== "") {
          if (this.uns_status_obj.Sales_Category == "FWR") {

            this.uns_status_obj.Discharge_Port.startsWith("GB") ? this.uns_importfwd = true : this.uns_exportfwd = true ;
            console.log("this.uns_importfwd",this.uns_importfwd);
            console.log("this.uns_exportfwd",this.uns_exportfwd);
            // this.uns_importfwd = true;

          } else if (this.uns_status_obj.Discharge_Port.startsWith("GB")) {
            this.uns_importsea = true;
          } else {
            this.uns_exportsea = true;
          }
        }

      } else if (this.uns_status_obj.Transport_Mode == "AIR") {
        if (typeof this.uns_status_obj.Discharge_Port != "undefined") {
          if (this.uns_status_obj.Discharge_Port.startsWith("GB")) {
            this.uns_importair = true;
          } else {
            this.uns_exportair = true;
          }
        }

      }
      this.shipment_reference = this.uns_status_obj.Shipment_Reference;
      this.uns_documents2 = this.uns_missing_documents_2(this.uns_status_obj, this);


    }

  }

  copySmartLink(){
    let item = this.smartLink;
    console.log("item",item)
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand("copy");
    this.copied = true;
    setTimeout(()=>{
        this.copied = false;
    },5000)
  }

  searchAgain(){
    // console.log("function called");
    // document.body.style.overflow = 'hidden';
    this.forward_animation = false;
    this.showSideBarAnimation = false;
    setTimeout(()=>{
      this.router.navigate(['']);
  },1700)

//   setTimeout(()=>{
//     document.body.style.overflow = 'unset';
// },2000)
    

  }
  uns_missing_documents_2(status_obj, vm) {
    var uns_ETA = status_obj.ETA;
    let today = moment().format("DD/MM/YYYY");
    var uns_ETA_sub_seven = moment(uns_ETA, "DD/MM/YYYY").subtract(7, 'days').format("DD/MM/YYYY");
    var uns_documents_hide = moment(today, 'DD/MM/YYYY').isAfter(moment(uns_ETA_sub_seven, 'DD/MM/YYYY'));
    return (vm.uns_importsea || vm.uns_importair) && status_obj.AID_Event === '' && uns_documents_hide;
  }
  // DEPARTURE FUNCTION FOR TOP iNFORMATION BAR
  departureDate(estimatedtime, actualtime, mod,i) {
    // console.log(pagedItems)
    var estimated = estimatedtime;
    var actual = actualtime;
    var mode = mod;
    
    // console.log("index",i,"estimated",estimated,"actual",actual,"mode",mode);
    if (mode == "DEPDATEAVAIL") {
      return estimated == "" && actual == "" ? false : true;
    }
    if (mode == "DEPDATE") {
      // console.log("actual ", actual);
      // console.log("estimated ", estimated);
      return actual !== "" ? moment(actual, 'DD/MM/YYYY hh:mm:ss').format("DD/MM/YYYY") : moment(estimated, 'DD/MM/YYYY hh:mm:ss').format("DD/MM/YYYY");
    }
    if (mode == "DEPDATECONF") {
    
      return actual !== "" ? true : false;
    }
    if (mode == "DEPDATECONFRED") {
      var ATAPLUS4 = moment(actual, "DD/MM/YYYY").add(4, 'days').format("DD/MM/YYYY");
      var red = moment(ATAPLUS4, 'DD/MM/YYYY ').isAfter(moment().format("DD/MM/YYYY"));
      return actual !== "" && red ? true : false;

    }
    if (mode == "DEPDATEESTIM") {

      return actual == "" ? estimated !== "" ? true : false : false;
    }
    if (mode == "DEPDATEQUES") {
      return actual == "" && estimated == "" ? true : false;
    }
  }

  open(contId) {

    var modal = document.getElementById(contId);
    modal.style.display = "flex";
  }
  close(contId: string) {
    var modal = document.getElementById(contId);
    modal.style.display = "none";
    this.commInvImpUpload = false;
    this.commInvExpUpload = false;
    this.nesDecUpload = false;
    this.gspCertImpUpload = false;
    this.comInvoicefile = new FormData();

  }
  onFileChange(event, cert_name, inv_type, u_type: string = '') {

    this.f_size_err = false;
    this.f_type_err = false;

    let f: any = [];

    f = u_type == 'dnd' ? event : event.target.files[0];
    if (f.size > 25000000) {
      this.f_size_err = true;
    } else {
      if (f.type == "image/png" || f.type == "image/svg+xml") {
        this.f_type_err = true;
      } else {
        this.comInvoicefile.append(cert_name, "on");
        this.comInvoicefile.append("files", f);
        let shipmentNo = this.uns_status_obj.Shipment_Reference;
        this.comInvoicefile.append("jobNo", shipmentNo);
        this.comInvoicefile.append("debug", "0")
        this.comInvoicefile.append("toEmailAddress", this.uns_status_obj.Operator_Rep_Email)
        let f_name = f.name.length < 13 ? f.name : f.name.slice(0, 12) + "...";

        switch (inv_type) {
          case 1:
            this.commInvImpUpload = true;
            this.commInvUploadf_name = f_name;
            this.uploadFileService(0)
            break;
          case 2:
            this.gspCertImpUpload = true;
            this.gspCertImpUploadf_name = f_name;
            this.uploadFileService(0)
            break;
          case 3:
            this.commInvExpUpload = true;
            this.commInvExpUploadf_name = f_name;
            this.uploadFileService(1)
            break;
          case 4:
            this.nesDecUpload = true;
            this.nesDecUploadf_name = f_name;
            this.uploadFileService(1)
            break;
          default:
            break;
        }

      }
    }

  }
  clearUpload(cert_type) {
    switch (cert_type) {
      case 1:
        this.commInvImpUpload = false;
        this.commInvUploadf_name = '';
        break;
      case 2:
        this.gspCertImpUpload = false;
        this.gspCertImpUploadf_name = '';
        break;
      case 3:
        this.commInvExpUpload = false;
        this.commInvExpUploadf_name = '';
        break;
      case 4:
        this.nesDecUpload = false;
        this.nesDecUploadf_name = '';
        break;
      default:
        break;
    }
  }
  uploadFileService(uploadcontainerId: number) {
    let containerId = "uploadContainer";
    if (uploadcontainerId == 1) {
      containerId = "uploadContainer1";
    }
    this.isuploaded = true;
    
    this.apiService.uploadFileToServerService(this.comInvoicefile).subscribe((res: any) => {
      if (res.success) {
        this.msg = res.message;
        this.showSuccess('top-right', this.msg);
        this.close(containerId);
      } else {
        this.msg = res.message;
        this.showError('top-right', this.msg);
      }
      this.isuploaded = false;
    });

  }


  showSuccess(position: any = 'top-right', message: string) {
    this.toastr.successToastr(message, 'Success!', {
      position: position
    });
  }

  showError(position: any, message: string) {
    this.toastr.errorToastr(message, 'Oops!', {
      position: position
    });
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allSipmentDatas.length, page);

    // get current page of items

    this.pagedItems = this.allSipmentDatas.slice(this.pager.startIndex, this.pager.endIndex + 1);
    
  }

  getContainerDetailsData() {
    this.connProblem = false;
    if( !config.connectionCheck) {
      this.connProblem = true;
      this.error = false;
      this.emptyRes = false;
      return;
    }
    this.search = true;
    this.apiService.getContainerDetails(this.number).subscribe((data: any) => {
      config.setContainerDetails(data,this.number);
      this.search = false;
      if (config.unsworthobj && config.unsworthobj.length > 0) {
        this.setContainerData();

      } else {
        this.emptyRes = true;
        if(this.debug){
          console.error("Returns Empty JSON")
        }
        
      }
    }, (error: any) => { 
      console.error(`Backend returned code ${error.status}, ` +`body was: ${JSON.stringify(error.error)}`);
      this.error = true; 
    });

  }
  setContainerData() {

    this.allSipmentDatas = config.unsworthobj ? config.unsworthobj : [];
    this.setPage(1)
    this.ngOnInit()
  }
  getFileChange(e: FileList, cert_name, cert_type) {
    this.file = e[0]
    this.onFileChange(this.file, cert_name, cert_type, 'dnd')
  }
  displayCounter(e: any) {
    this.milestone_completed = e;

  }
}
