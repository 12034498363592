import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router'; // import Router and NavigationEnd
import { ConnectionService } from 'ng-connection-service';
import * as config from './services/api.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Unsworth';
  isConnected: any = true;
  constructor(private router: Router, private connectionService: ConnectionService) {
    
 }

 ngAfterViewInit(): void {
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      (<any>window).ga('set', 'page', event.urlAfterRedirects);
      (<any>window).ga('send', 'pageview');
    }
  });
  this.connectionService.monitor().subscribe(isConnected => {
   this.isConnected = isConnected;
   if (this.isConnected) {
     // this.status = "ONLINE";
     config.setConnectionCheck(true);
   }
   else {
     config.setConnectionCheck(false);
     // this.status = "OFFLINE";
   }
 });

 }
      
}
