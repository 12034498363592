import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-milestone1',
  templateUrl: './milestone1.component.html',
  styleUrls: ['./milestone1.component.css']
})
export class Milestone1Component implements OnInit {
  b_made: boolean;
  g_departed: boolean;
  g_arrived: boolean;
  c_cleared: boolean;
  g_delivered: boolean;
  @Input() unsDetails: any;

  @Output() valueChange = new EventEmitter();
  constructor() {
    this.b_made = true;
    this.g_departed = false;
    this.g_arrived = false;
    this.c_cleared = false;
    this.g_delivered = false;
  }

  ngOnInit() {
    this.milestoneFunctionality();
  }
  milestoneFunctionality() {
    if (this.unsDetails.Actual_Delivery) {
      this.g_departed = true;
      this.g_arrived = true;
      this.c_cleared = true;
      this.g_delivered = true;
      this.valueChange.emit(true);

    }
    if (this.unsDetails.CLR_Event) {
      this.g_departed = true;
      this.g_arrived = true;
      this.c_cleared = true;


    }
    if (this.unsDetails.ATA) {
      this.g_departed = true;
      this.g_arrived = true;
    }
    if (this.unsDetails.ATD) {
      this.g_departed = true;
    }
  }

}