import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from  '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { StatusComponent } from './status/status.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { Milestone1Component } from './component/milestones/milestone1/milestone1.component';
import { Milestone2Component } from './component/milestones/milestone2/milestone2.component';
import { Milestone3Component } from './component/milestones/milestone3/milestone3.component';
import { NotificationComponent } from './component/notification/notification.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ng6-toastr-notifications';
import { PagerService } from './services/index.service';
import { SanitizeHtmlPipe } from './html-sanitize.pipe';
import { DndDirective } from './dnd.directive';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    StatusComponent,
    HeaderComponent,
    FooterComponent,
    Milestone1Component,
    Milestone2Component,
    Milestone3Component,
    NotificationComponent,
    SanitizeHtmlPipe,
    DndDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxUploaderModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    ToastrModule.forRoot()
  ],
  providers: [PagerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
