import { Component, OnInit } from '@angular/core';
import * as config from '../../services/api.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  smartLink: string='';
  copied: boolean = false;
  constructor() { }

  ngOnInit() {
    console.log(config.queryUrl)
    this.smartLink = config.queryUrl!=''?config.queryUrl:'';
  }
  copySmartLink(){
    let item = this.smartLink;
    console.log("item",item)
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand("copy");
    this.copied = true;
    setTimeout(()=>{
        this.copied = false;
    },5000)
  }

}

