import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as config from '../../services/api.config';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() classif: any;
  uns_hide_article:boolean = false;
  uns_importair_noti: boolean;
  uns_exportair_noti: boolean;
  uns_importsea_noti: boolean;
  uns_exportsea_noti: boolean;
  uns_importfwd_noti: boolean;
  uns_exportfwd_noti: boolean;
  uns_status_obj_noti: any;
  milestone_complete:boolean = false;

  
  find_import_export_fwd:String;
  final_milestone_import:boolean;
  final_milestone_export:boolean;
  final_milestone_fwd:boolean;
 

  arrivesoon_notdelivery:boolean;
  arrivesoon_notdelivery_else:boolean;

  customercare_executive_img:String;

  customer_mailto:String;
  feedback_survey:String;

  uns_process_show:boolean;
  uns_documents2:boolean;
  

  constructor() {
    this.uns_importair_noti = false;
    this.uns_exportair_noti = false;
    this.uns_importsea_noti = false;
    this.uns_exportsea_noti = false;
    this.uns_importfwd_noti = false;
    this.uns_exportfwd_noti = false;
    this.uns_status_obj_noti = {};

    
    this.find_import_export_fwd = "";
    this.final_milestone_import = false;
    this.final_milestone_export = false;
    this.final_milestone_fwd = false;


    this.arrivesoon_notdelivery = false;
    this.arrivesoon_notdelivery_else = false;
    this.customercare_executive_img = "";

    this.customer_mailto = "";
    this.feedback_survey = "";

   
   }

  ngOnInit() {
    this.uns_importair_noti = this.classif.uns_importair;
    this.uns_exportair_noti = this.classif.uns_exportair;
    this.uns_importsea_noti = this.classif.uns_importsea;
    this.uns_exportsea_noti = this.classif.uns_exportsea;
    this.uns_importfwd_noti = this.classif.uns_importfwd;
    this.uns_exportfwd_noti = this.classif.uns_exportfwd;
    this.uns_status_obj_noti = this.classif.uns_status_obj;
    this.milestone_complete = this.classif.milestone_completed;
    
    this.customercare_executive_img = this.uns_status_obj_noti && (config.imgURL+this.uns_status_obj_noti.Rep_Code+'.png');
    this.customer_mailto = this.uns_status_obj_noti ? this.uns_status_obj_noti.Operator_Rep_Email:"";
    this.feedback_survey = this.uns_status_obj_noti ? "https://unsworthuk.typeform.com/to/z1Fli7?ccrep="+encodeURI(this.uns_status_obj_noti.Operator_Rep)+"&shipmentref="+this.uns_status_obj_noti.Shipment_Reference: ""; 

    //142 Customer Care Representative Notification
    if(this.uns_importair_noti || this.uns_importsea_noti){
     if(this.uns_status_obj_noti.Actual_Delivery !== undefined && this.uns_status_obj_noti.Actual_Delivery !== "") {
      this.find_import_export_fwd = "importcustomer";
      this.final_milestone_import = true;
     }
    }else if(this.uns_exportair_noti || this.uns_exportsea_noti){
      if(this.uns_status_obj_noti.ATA !== undefined && this.uns_status_obj_noti.ATA !== "") {
        this.find_import_export_fwd = "exportcustomer";
        this.final_milestone_export = true;
       }
    }else if(this.final_milestone_fwd){
      if(this.uns_status_obj_noti.RLS_Event !== undefined && this.uns_status_obj_noti.RLS_Event !== "") {
        this.find_import_export_fwd = "fwd";
        this.final_milestone_fwd = true;
       }
    }
    // 142 End
   
  
    // 143 Goods At Risk of Incuring Storage Charge , Responsive Notification

    // NEW FUNCTION
    

    // 143 END
  

    let vm = this;
    if(this.uns_status_obj_noti){
      this.uns_process_show = uns_process_shipment(this.uns_status_obj_noti,vm);
      this.uns_documents2 = uns_missing_documents_2(vm.uns_status_obj_noti, vm);
    }
    
    
    function uns_process_shipment(status_obj,vm) {
      
      var uns_ETA = status_obj.ETA;
      var uns_ATA = status_obj.ATA;
      // console.log(uns_ATA);
      var uns_process_hide = true;
      if(uns_ETA === '' || uns_ATA === '') {
        uns_process_hide = false;
      }

      if(uns_ATA !== '') {
        var today_date = moment().format('DD/MM/YYYY');
        uns_process_hide = moment(today_date,'DD/MM/YYYY').isAfter(moment(uns_ATA, 'DD/MM/YYYY'));
      }
      
      // console.log(uns_process_hide);
      var uns_process_flag = (vm.uns_importsea_noti || vm.uns_importair_noti) && status_obj.AID_Event !== '' && uns_process_hide;
      return uns_process_flag;
    
    }

    function uns_missing_documents_2(status_obj, vm) {
      var uns_ETA = status_obj.ETA;
      var subtract_seven = moment().subtract(7, 'days').format("DD/MM/YYYY");
      var uns_documents_hide = moment(subtract_seven, 'DD/MM/YYYY').isSame(moment(uns_ETA, 'DD/MM/YYYY'));
      return (vm.uns_importsea_noti || vm.uns_importair_noti) && status_obj.AID_Event === '' && uns_documents_hide;
    }
    
  
  
  
    // init ends
  }
  
  sailingtime(section){
    if(section == "SAILING"){
        let arrivaltime = this.uns_status_obj_noti.ATA;
        let depaturetime =  this.uns_status_obj_noti.ATD;
        let days =  moment(arrivaltime,'DD/MM/YYYY').diff( moment(depaturetime,'DD/MM/YYYY'),'days');
        return days;
    }
    if(section == "LASTMILE"){
    
        let arrivaltime = this.uns_status_obj_noti.ATA;
        let actualdelivery = this.uns_status_obj_noti.Actual_Delivery;
        let lastmiledays =  moment(actualdelivery,'DD/MM/YYYY').diff( moment(arrivaltime,'DD/MM/YYYY'),'days');
        return lastmiledays;
      
      
    }
    if(section == "TOTAL"){
      let arrivaltime = this.uns_status_obj_noti.ATA;
      let depaturetime =  this.uns_status_obj_noti.ATD;
      let actualdelivery = this.uns_status_obj_noti.Actual_Delivery;
      let days =  moment(arrivaltime,'DD/MM/YYYY').diff( moment(depaturetime,'DD/MM/YYYY'),'days');
      let lastmiledays =  moment(actualdelivery,'DD/MM/YYYY').diff( moment(arrivaltime,'DD/MM/YYYY'),'days');
        return days + lastmiledays;

      }
      
   
    
  }

  // comer shipment arrive soon not delivery
  notdeliver(){
    if(this.uns_status_obj_noti.Estimated_Delivery == "" && this.uns_status_obj_noti.Estimated_Delivery.ETA !== ""){
      let eta_datesubfive =  moment(this.uns_status_obj_noti.Estimated_Delivery.ETA,"DD/MM/YYYY").subtract(5, 'days').format("DD/MM/YYYY");
      let today = moment().format("DD/MM/YYYY");  
      return moment(eta_datesubfive, 'DD/MM/YYYY ').isAfter(moment(today, 'DD/MM/YYYY'));
    }
  }
  // end comer shipment arrive soon not delivery
  // New Function for Notification Non Escalating

  notif_non_escalation(){
    if(this.uns_status_obj_noti && (this.uns_importsea_noti || this.uns_importair_noti) && this.uns_status_obj_noti.AID_Event !== "" ){
      var eta_ata = this.uns_status_obj_noti.ATA !== ""?  this.uns_status_obj_noti.ATA : this.uns_status_obj_noti.ETA !== ""? this.uns_status_obj_noti.ETA : "empty";
      var today = moment().format("DD/MM/YYYY");
      if(eta_ata !== "empty"){
        eta_ata =  moment(eta_ata,"DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY");
        let eta_hide = moment(eta_ata, 'DD/MM/YYYY ').isAfter(moment(today, 'DD/MM/YYYY'));
        return !eta_hide;
        
      }
      return true;
    }
  }

  good_atrisk(risktype){

    if(this.uns_status_obj_noti && (this.uns_importsea_noti || this.uns_importair_noti || this.uns_importfwd_noti)){
      if(this.uns_status_obj_noti.Estimated_Delivery == "" && this.uns_status_obj_noti.RLS_Event == "" && risktype == "tplus2"){
        // var temptest = "05/01/2019";
        // this.uns_status_obj_noti.CLR_Event = "25/12/2018";
        if(this.uns_status_obj_noti.CFS_Available_Date !== "" || this.uns_status_obj_noti.CLR_Event !== "" || this.uns_status_obj_noti.UNP_Event !== "" ){
            var avail_date = this.uns_status_obj_noti.CLR_Event !== "" ? this.uns_status_obj_noti.CLR_Event : this.uns_status_obj_noti.UNP_Event !== "" && this.uns_importfwd_noti ? this.uns_status_obj_noti.UNP_Event : "empty" ;
            var avail_date1 = avail_date == "empty" ? this.uns_status_obj_noti.CFS_Available_Date !== "" ? this.uns_status_obj_noti.CFS_Available_Date : "empty" : avail_date;
            console.log("testing@@@",avail_date,"yummy",avail_date1);
            if(avail_date1 !== "empty"){
             let  availplus2 =  moment(avail_date1,"DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY");
             let today = moment().format("DD/MM/YYYY");
             console.log("temp moment",moment(availplus2, 'DD/MM/YYYY ').isAfter(moment(today, 'DD/MM/YYYY')));
              return moment(today, 'DD/MM/YYYY ').isAfter(moment(availplus2, 'DD/MM/YYYY'));
            }
            else{return false;}
          }
      }
      if(this.uns_status_obj_noti.Actual_Delivery == "" && this.uns_status_obj_noti.RLS_Event == "" && risktype == "tplus5"){
        if(this.uns_status_obj_noti.CFS_Available_Date !== "" || this.uns_status_obj_noti.CLR_Event !== "" || this.uns_status_obj_noti.UNP_Event !== "" ){
            var avail_date = this.uns_status_obj_noti.CLR_Event !== "" ? this.uns_status_obj_noti.CLR_Event : this.uns_status_obj_noti.UNP_Event !== "" && this.uns_importfwd_noti ? this.uns_status_obj_noti.UNP_Event : "empty" ;
            var avail_date1 = avail_date == "empty" ? this.uns_status_obj_noti.CFS_Available_Date !== "" ? this.uns_status_obj_noti.CFS_Available_Date : "empty" : avail_date;
            if(avail_date1 !== "empty"){
             let  availplus5 =  moment(avail_date1,"DD/MM/YYYY").add(4, 'days').format("DD/MM/YYYY");
             let today = moment().format("DD/MM/YYYY");
             console.log("today",today);
             console.log("avail_date1",avail_date1);
              return moment(today, 'DD/MM/YYYY ').isAfter(moment(availplus5, 'DD/MM/YYYY'));
            }
            else{return false;}
          }
      }
    }
    else{return false;}
  }


  // End of non escalating function
}

