import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import * as config from '../services/api.config';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  contNo: string = '';
  secretNo: string = '';
  model: any = {};
  error: boolean;
  search: boolean;
  loaderSearch:boolean = false;
  connProblem: boolean = false;
  constructor(private apiService: ApiService, private router: Router) {
    this.search = false;
  }

  ngOnInit() {

  }
  getContainerDetails() {
    console.log('ytytdkjfhdf')
    this.loaderSearch = true;


    
    this.search = true;
    this.connProblem = false;
    if( !config.connectionCheck) {
      this.connProblem = true;
      this.error = false;
      this.search = false;
      return;
    }
    this.apiService.getContainerDetails(this.model.contNo).subscribe((data: any) => {
      console.log('ytytu',data)
      config.setContainerDetails(data, this.model.contNo);
      this.search = false;
      setTimeout(()=>{this.loaderSearch = false;
        if (config.unsworthobj && config.unsworthobj.length > 0) {
          // console.log("this.model.contNo",this.model.contNo);
          // window.location.href = "https://pathway.live/launch.html?refno="+this.model.contNo;
          this.router.navigate(['status'], { queryParams: { number: this.model.contNo }});
  
        } else {
          this.error = true;
        }
      
      },2000)
    
    },(error: any)=>{
      this.error = true;
      this.search = false;
      console.error(`Backend returned code ${error.status}, ` +`body was: ${JSON.stringify(error.error)}`);
    });
    
  

  }
  checkBeforeEnter() {

    if (this.model.contNo !== "" && this.model.secretNo !== "" && typeof this.model.contNo !== "undefined" && typeof this.model.secretNo !== "undefined") {
      this.apiService.getContainerDetails(this.model.contNo).subscribe((data: any) => {

        if (data && data.length > 0) {
          this.error = false;
        } else {
          this.error = true;
        }
      });
    } else {
      this.error = false;
    }
  }

  open(contId) {

    var modal = document.getElementById(contId);
    modal.style.display = "flex";
  }
  close(contId: string) {
    var modal = document.getElementById(contId);
    modal.style.display = "none";
  }
}
