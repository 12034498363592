import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import * as config from './api.config';
// import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  unsworthdata:any;
  constructor(private http: HttpClient) { }

  getContainerDetails(contNo){
    // var no = contNo;
    // var seeret = secret;
    // let url = config.SearchAPI_Server+"/"+config.SearchAPI_Method+"?number="+contNo+"&pin="+secret;
    //let url = "./assets/development.json";
    // var header = {"Access-Control-Allow-Origin":"*"}
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    return this.http.get(config.SearchAPI_Server+"/"+config.SearchAPI_Method+"?number="+contNo);
  }
  // getContainerDetailsStatic(){
  //   return environment.unsworthStaticJson;
  // }
  uploadFileToServerService(data: any){
    // let url = config.Upload_URL;
    // let filedata =JSON.parse(JSON.stringify(data || null ));
    return this.http.post(config.Upload_URL,data);
  }
}
