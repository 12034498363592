import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-milestone3',
  templateUrl: './milestone3.component.html',
  styleUrls: ['./milestone3.component.css']
})
export class Milestone3Component implements OnInit {
  b_made: boolean;
  g_departed: boolean;
  g_arrived: boolean;
  g_released: boolean;
  @Input() unsDetails: any;
  @Output() valueChange = new EventEmitter();
  constructor() {
    this.b_made = true;
    this.g_departed = false;
    this.g_arrived = false;
    this.g_released = false;
  }

  ngOnInit() {
    this.milestoneFunctionality3();
  }
  milestoneFunctionality3() {
    if (this.unsDetails.ATA) {
      this.g_departed = true;
      this.g_arrived = true;
      this.g_released = true;
      this.valueChange.emit(true);
    }
    if (this.unsDetails.ATD) {
      this.g_departed = true;
      this.g_arrived = true;
    }
    if (this.unsDetails.Actual_Pickup) {
      this.g_departed = true;
    }
  }
}
