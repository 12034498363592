// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//   unsworthStaticJson: [  
//     {  
//        "Local_Client":"PREEMA INTERNATIONAL LIMITED",
//        "Sales_Category":"COM",
//        "Transport_Mode":"SEA",
//        "Load_Port":"CNNBO",
//        "Load_Port_Name":"Ningbo",
//        "Load_Country":"CN",
//        "Discharge_Port":"GBFXT",
//        "Discharge_Port_Name":"Felixstowe",
//        "Discharge_Country":"GB",
//        "Vessel_Name":"MSC JASMINE",
//        "Vessel_Voyage_Flight_number":"1",
//        "ETD":"20/10/2018",
//        "ATD":"",
//        "ETA":"19/11/2018",
//        "ATA":"19/11/2018",
//        "Shipment_Reference":"S00001359",
//        "Container_Number":"1234",
//        "Container_Mode":"",
//        "House_Bill_Of_Lading":"1",
//        "CFS_Available_Date":"23/11/2018 10:14:00",
//        "CLR_Event":"23/11/2018 10:10:28",
//        "Estimated_Delivery":"27/11/2018 10:30:00",
//        "Actual_Delivery":"",
//        "UNP_Event":"",
//        "CFS_Storage_Date":"30/11/2018",
//        "Estimated_Pickup":"23/11/2018 10:16:00",
//        "Actual_Pickup":"",
//        "RLS_Event":"",
//        "Rep_Code":"TEL",
//        "Operator_Rep":"Tom Ellis",
//        "Operator_Rep_Email":"tom@ugl.digital",
//        "Receiving_Agent":"Unsworth (UK) Limited",
//        "Receiving_Agent_Phone":"+442085398899",
//        "AID_Event":"",
//        "Arrival_CFS_Address":"EAGLE FREIGHT TERMINAL, UNIT 3&-4 LODGE LANE, IPSWICH, IP6 0LB",
//        "AED_Event":""
//     }
//  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
